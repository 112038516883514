<template>
	<ul class="menu-nav">
		<router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon fas fa-tachometer-alt"></i>  <span class="menu-text">Dashboard</span>
				</a>
			</li>
		</router-link>
		<router-link to="/agenda/liste" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon flaticon2-calendar"></i>  <span class="menu-text">Agenda</span>
				</a>
			</li>
		</router-link>
		<router-link to="/user/liste" v-slot="{ href, navigate, isActive, isExactActive }" v-if="role === 'isAdmin'">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon flaticon2-user"></i>  <span class="menu-text">Utilisateurs</span>
				</a>
			</li>
		</router-link>
		<router-link to="/lead/liste" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon fas fa-gem"></i>  <span class="menu-text">{{$store.back.getText('leads')}}</span>
				</a>
			</li>
		</router-link>
		<router-link to="/devis/liste" v-slot="{ href, navigate, isActive, isExactActive }">
			<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
				<a :href="href" class="menu-link" @click="navigate"> <i class="menu-icon fas fa-clipboard-list"></i>  <span class="menu-text">{{$store.back.getText('devis')}}</span>
				</a>
			</li>
		</router-link>	
		<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }" v-if="role === 'isAdmin'">
			<a href="#" class="menu-link menu-toggle"> <i class="menu-icon fas fa-shopping-cart"></i>  <span class="menu-text">{{$store.back.getText('market')}}</span>  <i class="menu-arrow"></i>
			</a>
			<div class="menu-submenu"> <span class="menu-arrow"></span>
				<ul class="menu-subnav">
					<router-link to="/market/lots/disponible" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
							<a :href="href" class="menu-link" @click="navigate"> <i class="menu-bullet menu-bullet-dot"> <span></span> </i>  <span class="menu-text">Disponible</span>
							</a>
						</li>
          </router-link>
					<router-link to="/market/lots/postule" v-slot="{ href, navigate, isActive, isExactActive }">
						<li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active', isExactActive && 'menu-item-active' ]">
							<a :href="href" class="menu-link" @click="navigate"> <i class="menu-bullet menu-bullet-dot"> <span></span> </i>  <span class="menu-text">Postulé</span>
							</a>
						</li>
          </router-link>
				</ul>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
  name: "KTMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
	data(){
		return {
			'role':'isUser'
		};
	},
	beforeMount() {
		this.role = this.$store.getters.currentUser.role === 1 ? 'isAdmin':'isUser'
		console.log(this.$store.getters.currentUser.role);
	},
	mounted() {
	  //do something after mounting vue instance
	}
};
</script>
